// eslint-disable-next-line no-undef
window.dataLayer = window.dataLayer || [];

function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
}

gtag('js', new Date());

gtag('config', 'G-5LTXRGLNQP');